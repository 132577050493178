import React from 'react';
import styled from 'styled-components';

import Button from '../common/Button';
import Grid from '../common/Grid';
import Section from '../common/Section';
import { H1 } from '../common/Typography';

import { fadeIn } from '../../utils/keyframes';

const Inner = styled.div`
  animation: ${fadeIn} 1s ease;
  grid-column: span 12;
  text-align: center;
`;

export default function Main() {
  return (
    <Section>
      <Grid>
        <Inner>
          <H1>Oops, this page doesn&apos;t exist!</H1>
          <Button color="orange" to="/">
            Back to home
          </Button>
        </Inner>
      </Grid>
    </Section>
  );
}
