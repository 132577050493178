import React from 'react';

import Layout from '../components/common/Layout';
import SEO from '../components/common/Seo';
import Main from '../components/404/Main';

const NotFoundPage = () => (
  <Layout>
    <SEO title="Page not found" />
    <Main />
  </Layout>
);

export default NotFoundPage;
